@import "@/assets/scss/style.scss";

.color-module-search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;

    @media (max-width: 1400px) {
        // flex-direction: column;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    &__filters {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        gap: 1rem;
        opacity: 0;
        max-height: 0;
        transform: translateY(-10px);
        overflow: hidden;
        transition: opacity 0.3s ease, transform 0.3s ease, max-height 0.3s ease;

        @media (max-width: 900px) {
            flex-direction: column; // Take up full row
        }

        & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            height: 100%;
            gap: 0.5rem;
            flex: 1;
            width: 100%;
        }
    }

    &__filters.filters-active {
        opacity: 1;
        max-height: 300px;
        transform: translateY(0);
    }

    & .color-module-search__search-wrap {
        flex: 0 0 auto; // Prevent shrinking
        width: auto; // Default width
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        gap: 0.5rem;

        @media (max-width: 1400px) {
            width: 100%; // Take up full row
        }
    }

    & .color-module-search__switch-wrap {
        margin-left: 3rem;
        min-width: 8rem;

        button {
            margin: 12px 0 5px 0;
        }
    }

    &__title {
        font-size: 0.8rem;
        font-weight: 600;
        color: #515151;
    }

    &__dropdown,
    &__input {
        width: 100%;
    }

    &__input-search {
        width: 15rem;

        @media (max-width: 1400px) {
            width: 100%;
        }
    }
}

::v-deep .ant-input-search-icon {
    cursor: auto;
}
