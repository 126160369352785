@import "@/assets/scss/style.scss";

.color-module {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: auto;
    width: 60vw;
    min-height: 80vh;
    max-height: 85vh;
    flex-wrap: nowrap;

    // add breakpoints for responsive design
    @media (max-width: 1900px) {
        width: 65vw;
    }

    @media (max-width: 1600px) {
        width: 70vw;
    }

    @media (max-width: 1400px) {
        width: 75vw;
    }

    @media (max-width: 1200px) {
        width: 80vw;
    }

    @media (max-width: 1000px) {
        width: 85vw;
    }

    @media (max-width: 800px) {
        width: 90vw;
    }

    @media (max-width: 600px) {
        width: 95vw;
    }

    &__tabs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        width: 100%;
    }

    &__title {
        font-size: 1.2rem;
        font-weight: 600;
        color: #333;
        position: relative;
        cursor: pointer;
        transition: color 0.3s;
    }

    &__title-icon {
        position: absolute;
        color: #b2b2b2;
        margin-left: 3px;
        margin-top: -5px;
        font-size: 15px;
    }

    &__info-modal {
        display: flex;
        flex-direction: column;
        gap: 7px;

        span {
            color: #3b3b3b;
        }
    }

    &__info-modal-title {
        font-weight: 600;
        margin-bottom: 1rem;
    }

    &__info-modal-group {
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding-left: 2rem;
    }

    &__info-modal-subtext {
        white-space: pre-wrap;
    }

    &__info-modal-bold {
        font-weight: bold;
    }

    &__info-modal-point {
        font-size: 1rem;
        margin-right: 5px;
    }
}

.title--active {
    color: #1890ff;
}

.title--search {
    // color: #9d9d9d;
    // cursor: default;
}

::v-deep .color-module-popup {
    z-index: 1010;
}

::v-deep .ant-spin {
    width: 100%;
}

::v-deep .ant-table-placeholder {
    margin-top: -10rem;
}
