@import "@/assets/scss/style.scss";

.color-module-filters {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
    padding-bottom: 5px;

    @media (max-width: 900px) {
        flex-direction: column; // Take up full row
    }

    & > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        gap: 0.5rem;
        width: 100%;
    }

    &__title {
        font-size: 0.8rem;
        font-weight: 600;
        color: #515151;
    }

    &__dropdown {
        // width: 10rem;
        width: 100%;
    }
}
