@import "@/assets/scss/style.scss";

.color-module-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;

    @media (max-width: 1400px) {
        margin-bottom: 1rem;
    }

    &__filters-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        width: 100%;
    }

    &__filters-row {
        width: 100%;
        opacity: 0;
        max-height: 0;
        transform: translateY(-10px);
        overflow: hidden;
        transition: opacity 0.3s ease, transform 0.3s ease, max-height 0.3s ease;
    }

    &__filters-row.filters-active {
        opacity: 1;
        max-height: 300px;
        transform: translateY(0);
    }
}
